import React, { useEffect } from 'react';
import { PiTarget } from "react-icons/pi";
import { CiLight } from "react-icons/ci";
import { FaChartLine } from "react-icons/fa";
import { useLocation } from 'react-router-dom';

const About = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
                window.scrollTo({
                    top: element.offsetTop - navbarHeight, 
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);

    return (
        <section className="bg-gray-900" id="about">
            <div className="container px-6 py-12 mx-auto">
                <h2 className="text-4xl font-semibold text-center capitalize lg:text-5xl text-white">Hakkımızda</h2>

                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-16">
                    <div className="flex flex-col items-center text-justify">
                        <FaChartLine className='text-blue-400' size={60} />
                        <h1 className="mt-4 text-2xl font-bold text-white text-center">MİSYONUMUZ</h1>
                        <p className="mt-4 text-lg text-gray-300">
                            Misyonumuz, yenilikçi, çevre dostu teknolojilerle deniz kirliliğini azaltmak, ekosistemi korumak
                            ve sürdürülebilir kalkınmaya katkı sağlamak. Otonom biyoreaktör sistemlerimizle deniz yüzeyindeki
                            katı atık, mikroplastik ve toksik kimyasalları temizlemeye öncülük etmeyi hedefliyoruz.
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-justify">
                        <CiLight className='text-yellow-400' size={60} />
                        <h1 className="mt-4 text-2xl font-bold text-white text-center">VİZYONUMUZ</h1>
                        <p className="mt-4 text-lg text-gray-300">
                            Vizyonumuz, başta Türkiye olmak üzere, dünya genelinde deniz temizliği ve sürdürülebilirlik
                            konusunda öncü bir teknoloji şirketi olmak ve çevreye duyarlı, sürdürülebilir bir gelecek yaratmaktır.
                            Akriha, yenilikçi çözümlerle mavi gezegenimizin korunmasını amaçlayan global bir lider olmayı hedeflemektedir.
                        </p>
                    </div>
                    
                    <div className="flex flex-col items-center text-justify">
                        <PiTarget className='text-green-400' size={60} />
                        <h1 className="mt-4 text-2xl font-bold text-white text-center">HEDEFİMİZ</h1>
                        <p className="mt-4 text-lg text-gray-300">
                            Hedefimiz, Türkiye’den başlayarak dünya genelinde deniz kirliliğine karşı çözümler sunmak.
                            2026’ya kadar pilot bölgelerde otonom cihazlarımızla deniz yüzeyindeki kirliliği %20 azaltmayı
                            ve öngörü teknolojileriyle gelecekteki kirlilik risklerini önlemeyi amaçlıyoruz.
                        </p>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default About;
