import React, { useEffect } from 'react'
import Slider from 'react-slick';
import { FaLinkedin } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const AboutUs = () => {
    
    const location = useLocation();

    const team = [
        {
            name: "Nesibe Nur Şahintürk",
            title: "Kurucu Ortak/CEO",
            img: "/assets/images/team/NesibeNurSAHINTURK.jpg",
            linkedin: "https://www.linkedin.com/in/nesibe-nur-%C5%9Fahint%C3%BCrk-932872226/",
        },
        {
            name: "Tuğba Gülşen",
            title: "Kurucu Ortak/CTO",
            img: "/assets/images/team/TugbaGULSEN.JPG",
            linkedin: "https://www.linkedin.com/in/tu%C4%9Fba-g%C3%BCl%C5%9Fen/",
        },
        {
            name: "Şevval Kamış",
            title: "AR-GE/Kimyager",
            img: "/assets/images/team/SevvalKAMIS.jpg",
            linkedin: "https://www.linkedin.com/in/%C5%9Fevval-kam%C4%B1%C5%9F-b25623284?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        },
        {
            name: "Eyüp Emre Anlar",
            title: "Mekanik/Gemi İnşaat Mühendisi",
            img: "/assets/images/team/EyupEmreAnlar.JPG",
            linkedin: "https://www.linkedin.com/in/ey%C3%BCpemreanlar1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        },
        {
            name: "Zeynep Küçüktankut",
            title: "AR-GE/Su Bilimleri Mühendisi",
            img: "/assets/images/team/ZeynepKUCUKTANKUT.jpg",
            linkedin: "https://www.linkedin.com/in/zeynep-k%C3%BC%C3%A7%C3%BCktankut-3759b0233?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
        },

        {
            name: "Muhammed A. Taştan",
            title: "Yazılım/Bilgisayar Mühendisi",
            img: "/assets/images/team/MuhammedAliTASTAN.jpg",
            linkedin: "https://www.linkedin.com/in/muhammed-ali-ta%C5%9Ftan-aa2028294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        },
        {
            name: "Sinan Asıf Karabay",
            title: "Elektronik/Mekatronik Teknikeri",
            img: "/assets/images/team/SinanAsifKARABAY.png",
            linkedin: "https://www.linkedin.com/in/sinan-as%C4%B1f-karabay-5b91b1308/?originalSubdomain=tr",
        },
        {
            name: "Büşra Ekmen",
            title: "Kurumsal İletişim/Grafik Tasarım",
            img: "/assets/images/team/BusraEKMEN.jpg",
            linkedin: "https://tr.linkedin.com/in/b%C3%BC%C5%9Fra-ekmen-8414a710a?original_referer=https%3A%2F%2Fwww.google.com%2F",
        },
    ];

    const settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
                window.scrollTo({
                    top: element.offsetTop - navbarHeight, 
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);

    return (
        <section
            className="bg-center bg-cover w-full h-auto flex items-center justify-center"
            id="aboutUs"
            style={{
                backgroundImage:
                    "url('/assets/images/background/bgabtt.jpg')",
            }}
        >
            <div className="w-full h-full bg-gray-900/60 flex flex-col items-center justify-center text-center">
                <div className="px-6 py-12 w-full max-w-4xl">
                    <h2 className="text-4xl font-semibold lg:text-5xl text-white capitalize">
                        Biz kimiz?
                    </h2>

                    <div className="grid grid-cols-1 mt-3">
                        <div>
                            <p className="mt-4 text-lg text-gray-50">
                                Akriha, deniz kirliliğini çözmek ve deniz ekosistemlerini korumak amacıyla yenilikçi,
                                çevre dostu teknoloji çözümleri geliştiren bir teknoloji girişimidir.
                                Biyofiltre sistemleri ve otonom teknolojiler kullanarak, deniz yüzeyindeki katı atıklar,
                                mikroplastikler ve toksik kimyasalların temizlenmesine öncülük etmeyi hedefliyoruz!
                                <br/>
                                <br/>
                                Hedefimiz başta Türkiye olmak üzere dünya genelinde deniz temizliği ve sürdürülebilirlik 
                                konusunda lider bir teknoloji şirketi olmaktır. Çevre dostu çözümlerimizle hem yerel hem de küresel 
                                ölçekte deniz kirliliğiyle mücadele ediyor, sürdürülebilir kalkınmayı destekliyoruz.
                                Akriha, geleceğe yönelik çevre bilinci yüksek, yenilikçi ve sürdürülebilir projelerle
                                doğaya katkı sağlamayı amaçlamaktadır.
                                <br/>
                                <br/>
                                Her adımımızda, bilimsel araştırmalar, ileri teknolojiler ve çevre duyarlılığıyla
                                denizlerin korunmasına katkıda bulunarak daha temiz bir dünya için çalışıyoruz.

                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-6xl mx-auto">
                    <Slider {...settings} className="w-full h-full">
                        {team?.map((member, i) => (
                            <div key={i} className="flex flex-col items-center justify-center p-6 transition-all duration-300 transform rounded-xl">
                                <div className="flex justify-center items-center mb-4">
                                    <img
                                        className="object-cover w-44 h-44 rounded-full ring-4 ring-gray-300"
                                        src={member?.img}
                                        alt={member?.name}
                                    />
                                </div>
                                <h1 className="text-2xl font-semibold capitalize text-white">{member?.name}</h1>
                                <p className="mt-2 capitalize text-gray-300">{member?.title}</p>

                                <div className='flex justify-center items-center gap-3 mt-3 text-white'>
                                    <a href={member?.linkedin} target="_blank" rel="noopener noreferrer" className="hover:text-blue-300">
                                        <FaLinkedin className="w-4 h-4" />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

            </div>
        </section>
    )
}

export default AboutUs