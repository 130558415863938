import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import Home from './main/pages/Home';
import Navbar from './main/components/navbar/Navbar';
import Footer from './main/components/footer/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />}/>
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
