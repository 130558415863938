import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash;
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
        window.scrollTo({
          top: targetElement.offsetTop - navbarHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <footer className="bg-gray-700 text-white" id='contact'>
      <div className="container px-6 py-8 mx-auto space-y-8 sm:space-y-0 sm:flex sm:justify-between">
        
        <div className="space-y-4">
          <h3 className="text-xl font-semibold">İletişim</h3>
          <ul className="space-y-2">
            <li><strong>Adres:</strong> Kazlıçeşme, Cinoğlu Çk No:4, 34020 Zeytinburnu/İstanbul</li>
            <li><strong>Telefon:</strong> +90 (542) 570 54 13</li>
            <li><strong>Email:</strong> info@akrihatech.com.tr</li>
          </ul>
        </div>

        <div className="w-full sm:w-1/2">
          <h3 className="text-xl font-semibold mb-4">Konum</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14743.777296580513!2d28.889907404042825!3d41.00439360974337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7a4d21e8395%3A0x6a1c15be1ff9d0cd!2sKazlı%C3%A7eşme%2C%20Cino%C4%9Flu%20%C3%87k%20No%3A4%2C%2034020%20Zeytinburnu%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1699737791562!5m2!1str!2str"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Harita"
          />
        </div>
      </div>

      <div className="py-4 text-center">
        <p className="text-sm text-gray-300">© Akriha Tech 2024. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
