import React, { useState } from 'react';
import About from '../components/about/About';
import References from '../components/references/References';
import AboutUs from '../components/about/AboutUs';

const Home = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const Spinner = () => {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="animate-spin rounded-full border-t-4 border-blue-500 w-16 h-16"></div>
      </div>
    );
  };

  return (
    <>
      <div className="relative w-full h-screen overflow-hidden" id='home'>
        <video
          className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
          autoPlay
          loop
          muted
          preload="auto"
          onLoadedData={handleVideoLoad} 
        >
          <source src="/assets/videos/v1.mp4" type="video/mp4" />
        </video>

        {!isVideoLoaded && <Spinner />}
        
        <div className="flex items-center justify-center w-full h-full bg-black/30">
          <div className="text-center text-white px-4 md:px-8">
            <h1 className="text-3xl font-semibold lg:text-4xl italic font-serif text-shadow-lg">
              Daha sürdürülebilir bir <span className="text-blue-300">suyun</span> geleceği için çalışıyoruz!
            </h1>
          </div>
        </div>
      </div>

      <About id="about" />
      <AboutUs />
      <References />
    </>
  );
};

export default Home;
